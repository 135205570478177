//Configuración específica de prod
import {merge} from 'lodash';
import configBase from './configBase';

const config = {
	"environment": "preprod",
	"apis": {
		"authApi": {
			"URL": "https://preprod.api.auth.safi.misionescontaduria.com"
		},
		"presupuestoApi": {
			"URL": "https://preprod.api.presupuesto.misionescontaduria.com/v1",
			"URL_V2": "https://preprod.api.presupuesto.misionescontaduria.com/v2",
			"VERSION": "https://preprod.api.presupuesto.misionescontaduria.com"
		},
		"coreApi": {
			"URL": "https://preprod.coreapi.misionescontaduria.com"
 		},
 		"fundsRequestsApi": {
 			"URL": "https://preprod.api.fondos.misionescontaduria.com"
 		}
	}
};

export default merge(configBase, config);