import React, { useState } from 'react'
import { parseFloatOrUndefined, parseIntOrUndefined } from '../../../../utils/utils';

export const useGoodsAndServicesForm = () => {

    // State
    const [id, setId] = useState('');
    const [provisionRowNumber, setProvisionRowNumber] = useState();
    const [description, setDescription] = useState();
    const [quantity, setQuantity] = useState();
    const [units, setUnits] = useState();
    const [unitPrice, setUnitPrice] = useState();
    const [totalAmount, setTotalAmount] = useState(0);
    const [observations, setObservations] = useState();

    //validations

    const addValidation = () => {
        if (provisionRowNumber && description && quantity && units && unitPrice) {
            return true;
        } else return false;
    };

    const withValueLimitImports = (inputObj) => {
        const { value } = inputObj;
        const maxValue = 999999999999.99;
        const minValue = -999999999999.99;
        if (value <= maxValue && value >= minValue || value == '-') return inputObj;
    };

    const defaultParams = {
        page: 0,
        size: 10
    };

    const measurementUnitList = [
        {
            id: 1,
            name: "Litros"
        },
        {
            id: 2,
            name: "Unidades"
        },
        {
            id: 3,
            name: "Kilogramos"
        },
        {
            id: 4,
            name: "Meses"
        }
    ];

    const stopPropagate = (callback) => {
        return (e) => {
            e.stopPropagation();
            callback(e);
        };
    };

    //Handle change
    const handleChangeId = (value) => {
        setId(value);
    }
    const handleChangeLine = (value) => {
        const intValue = parseIntOrUndefined(value || 0);
        const valueLimit = 9999;
        if (intValue <= valueLimit) setProvisionRowNumber(parseIntOrUndefined(value));
    };

    const handleChangeDescription = (value) => {
        setDescription(value);
    };

    const handleChangeAmount = (value) => {
        const intValue = parseIntOrUndefined(value || 0);
        const valueLimit = 9999;
        if (intValue <= valueLimit) {
            setQuantity(parseIntOrUndefined(value));
            handleChangeTotalPrice(unitPrice, value);
        };
    };

    const handleChangeUnits = (value) => {
        setUnits(value);
    };

    const handleChangeUnitPrice = (imputObj) => {
        setUnitPrice(imputObj?.floatValue);
        handleChangeTotalPrice(imputObj?.floatValue, quantity);
    };

    const handleChangeTotalPrice = (price, amountParam) => {
        const price_ = parseFloatOrUndefined(price || 0);
        const amount_ = parseIntOrUndefined(amountParam || 0);
        setTotalAmount(parseFloatOrUndefined(price_ * amount_));
    };

    const handleChangeObservation = (value) => {
        setObservations(value);
    };

    const clearForms = () => {
        setProvisionRowNumber('');
        setDescription('');
        setQuantity('');
        setUnits('');
        setUnitPrice('');
        setTotalAmount(0);
        setObservations('');
    };

    return {
        defaultParams,
        stopPropagate,
        id,
        measurementUnitList,
        provisionRowNumber,
        description,
        quantity,
        units,
        unitPrice,
        totalAmount,
        observations,
        handleChangeId,
        handleChangeLine,
        handleChangeDescription,
        handleChangeAmount,
        handleChangeUnits,
        handleChangeUnitPrice,
        handleChangeTotalPrice,
        handleChangeObservation,
        addValidation,
        clearForms,
        withValueLimitImports
    };
};
